/* eslint-disable no-irregular-whitespace */
import React from 'react';
import LinearCTAButton from 'src/components/LinearCTAButton';
import { Box, Container } from '@material-ui/core';
import styles from './Campaign.module.scss';

export default function Campaign() {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <Box className={styles.card}>
          <Box>
            <h2 className={styles.title}>
              ペイディ加盟店手数料
              <br />
              3ヶ月無料キャンペーン
            </h2>
            <p className={styles.text}>
              対象期間中にShopify Plus Platform
              Packageご利用でペイディを新規導入すると
              3ヶ月間加盟店手数料が無料となります。
            </p>
            <LinearCTAButton
              text="今すぐ申し込む"
              to="/merchant/application/#documents"
              className={styles.button}
            />
            <p className={styles.textBottom}>
              ※本申込者情報はキャンペーン対象を判別する理由でShopify
              Japanへ共有をさせていただきます。 <br />
            </p>
          </Box>
        </Box>

        <Box className={styles.conditions}>
          <Box>
            <Box className={styles.condition}>
              <p className={styles.title}>対象期間</p>
              <Box className={styles.content}>
                <p className={styles.text}>2023年11月1日から2023年12月31日</p>
              </Box>
            </Box>
            <Box className={styles.condition} mt={4}>
              <p className={styles.title}>対象</p>
              <Box className={styles.content}>
                <p className={styles.text}>Shopify Plusをご利用する企業様</p>
              </Box>
            </Box>
            <Box className={styles.condition} mt={4}>
              <p className={styles.title}>無料適用期間</p>
              <Box className={styles.content}>
                <p className={styles.text}>2024年2月から4月支払い分</p>
              </Box>
            </Box>
            <Box className={styles.condition} mt={2}>
              <p className={styles.title}>加盟店手数料無料の上限金額</p>
              <Box className={styles.content}>
                <p className={styles.text}>5万円</p>
              </Box>
            </Box>
          </Box>

          <Box className={styles.condition}>
            <p className={styles.title}>ご利用金額とキャンペーン特典</p>
            <Box className={styles.content}>
              <p className={styles.text}>
                <b>キャンペーン期間中</b>
                <br />
                初期費用０円
                <br />
                月額費用０円
                <br />
                固定費用０円
                <br />
                決済手数料（加盟店手数料）
                <br />
                物販０％
              </p>
            </Box>
            <Box className={styles.content}>
              <p className={styles.text}>
                <b>キャンペーン終了後</b>
                <br />
                初期費用０円
                <br />
                月額費用０円
                <br />
                固定費用０円
                <br />
                決済手数料（加盟店手数料）
                <br />
                決済手数料は1回払い3.5％、
                <br />
                3回払い4.5％
              </p>
              <p className={styles.note}>
                ※6回あと払い、12回あと払い、については別途お申し込みが必要となります。
              </p>
            </Box>
          </Box>
        </Box>
      </Container>
    </section>
  );
}
